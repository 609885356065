<template>
  <div class="field-overview">
    <div class="field-overview__breadcrumb app-breadcrumb">
        <span class="app-breadcrumb__item" @click="$router.push('/bens/talhoes')">Talhões</span>
        <span class="app-breadcrumb__divider">></span>
        <span class="app-breadcrumb__item app-breadcrumb__item--disabled">Visão Geral</span>
    </div>

    <div class="field-overview__header">
        <span class="title" >Visão Geral {{ fieldSelected.name }}</span>
        <button class="btn btn-primary--outlined btn--flat" @click="$router.push('/bens/talhoes')">
            <i class="fa fa-arrow-left"/>
            <span>Voltar</span>
        </button>
    </div>

    <div class="field-overview__nav">
        <button class="btn btn--flat" :class="{ 'active': localization }" @click="showLocalization">
            <i class="material-icons">gps_fixed</i>
            <span>Localização<span v-if="hasPermission('ndvi')"> e NDVI</span></span>
        </button>
        <button class="btn btn--flat" :class="{ 'active': lifecycle }" @click="showLife">
          <i class="material-icons">timeline</i>
          <span>Vida do Talhão</span>
      </button>
    </div>

    <div class="field-overview__localization " v-show="localization">
        <div class="map-container" :class="{'full-width': !hasPermission('ndvi')}">
            <span>Localização do Talhão</span>
            <field-overview-map class="map" :field="fieldSelected"/>
        </div>

        <div class="ndvi-container" v-if="hasPermission('ndvi')">
            <span>Imagem Satélite</span>
            <field-overview-ndvi class="ndvi" :imagesNDVI="getImagesNDVI" v-if="getImagesNDVI.length > 0"/>
            <div class="flex-container pt-4 text-secondary" v-else>
                <i class="fa fa-exclamation-circle fa-2x"/>
                <span>Este talhão não contém imagens</span>
            </div>
        </div>
    </div>

    <div class="field-overview__lifecycle" v-show="lifecycle">
        <div class="text-secondary text-center pt-4" v-if="!activitiesLoaded">
            <span class="spinner-border"/>
        </div>

        <div class="flex-container pt-4 text-secondary" v-if="activitiesLoaded && !myActivities.length">
            <i class="fa fa-exclamation-circle fa-2x"/>
            <span>Esse talhão não possui dados</span>
        </div>

      <div v-else class="container">
        <button v-if="activitiesLoaded && myActivities.length && !loading" class="btn btn-primary--outlined btn--flat" @click="downloadPdf">
            <i class="fa fa-file"/>
            <span>Baixar PDF</span>
        </button>
        <div class="text-secondary text-center pt-4" v-if="loading">
            <span class="report-toast">Carregando relatório</span>
            <span class="spinner-border"/>
        </div>
        <div v-for="(item, i) in myActivities" :key="i" id="timeline">

          <div v-if="item.type == 'harvest' && (i%2) != 1" class="timeline-item">

            <div class="timeline-content-date right">
              <p>Colheita</p>
              <p style="font-size: 15px;">
                {{ item.activityDate.dateHour | formatDate  }}
              </p>
            </div>

            <div class="timeline-icon-colheita">
              <img src="@/assets/img/colheita.png">
            </div>

            <div class="timeline-content">
              <div :id="i" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <p>Umidade dos Grãos: {{ item.humidityGrains.value }}</p>
                <p v-if="item.area || item.area === 0">Área utilizada (ha): {{ item.area  | formatNumber }}</p>
                <p v-for="itemOperator in item.operators" :key="itemOperator.operator.name">Operador: {{ itemOperator.operator.name }}</p>
                <p v-for="itemMachinery in item.machineries" :key="itemMachinery.machinery.model">Equipamento: {{ itemMachinery.machinery.model }}</p>
                <p>Produção(sacas): {{item.production.value}}</p>
                <p>Produtividade(Quilo): {{item.productivity.value}}</p>
                <p>Fase da cultura: {{ item.culturePhase && item.culturePhase.abbreviation ? item.culturePhase.abbreviation : '-' }}</p> 
              </div>
              <p>Título: {{ item.title }}</p> 
              <p>Subarea: {{ item.subarea.name }}</p> 
              <p>Talhão: {{ item.subarea.field.name }}</p> 
              <button class="btn btn--flat" data-toggle="collapse" :data-target="`#${i}`" aria-expanded="false" :aria-controls="`${i}`" style="color: green; width: 100%;">Ver mais </button>
            </div>
          </div>

            <div v-else-if="item.type == 'harvest' && (i%2) != 0" class="timeline-item">

            <div class="timeline-content-date left">
              <p style="margin-left: 370px;">Colheita</p>
              <p style="font-size: 15px; margin-left: 370px;">
                {{ item.activityDate.dateHour | formatDate }}
              </p>
            </div>

            <div class="timeline-icon-colheita">
              <img src="@/assets/img/colheita.png">
            </div>

            <div class="timeline-content right">
              <div :id="i" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <p>Umidade dos Grãos: {{ item.humidityGrains.value }}</p>
                <p v-if="item.area || item.area === 0">Área utilizada (ha): {{ item.area | formatNumber }}</p>
                <p v-for="itemOperator in item.operators" :key="itemOperator.operator.name">Operador: {{ itemOperator.operator.name }}</p>
                <p v-for="itemMachinery in item.machineries" :key="itemMachinery.machinery.model">Equipamento: {{ itemMachinery.machinery.model }}</p>
                <p>Produção(sacas): {{item.production.value}}</p>
                <p>Produtividade(Quilo): {{item.productivity.value}}</p>
                <p>Fase da cultura: {{ item.culturePhase && item.culturePhase.abbreviation ? item.culturePhase.abbreviation : '-' }}</p> 
              </div>
              <p>Título: {{ item.title }}</p> 
              <p>Subarea: {{ item.subarea.name }}</p> 
              <p>Talhão: {{ item.subarea.field.name }}</p> 
              <button class="btn btn--flat" data-toggle="collapse" :data-target="`#${i}`" aria-expanded="false" :aria-controls="`${i}`" style="color: green; width: 100%;">Ver mais </button>
            </div>
          </div>

          <div v-if="item.type == 'pulverization' && (i%2) != 1" class="timeline-item">

            <div class="timeline-content-date right">
              <p>Pulverização</p>
              <p style="font-size: 15px;">
                {{ item.activityDate.dateHour | formatDate }}
              </p>
            </div>

            <div class="timeline-icon-pulverizacao">
              <img src="@/assets/img/prague.png">
            </div>

            <div class="timeline-content">
              <div :id="i" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <p>Notas: {{ item.notes }}</p>
                <p v-for="itemMachinery in item.machineries" :key="itemMachinery.machinery.model">Equipamento: {{ itemMachinery.machinery.model }}</p>
                <p v-if="item.area || item.area === 0">Área utilizada (ha): {{ item.area  | formatNumber }}</p>
                <p>Vazão (L/ha): {{ item.flowRate.value }}</p>
                <p v-for="itemProducts in item.products" :key="itemProducts.product.name">Produto: {{ itemProducts.product.name }}</p>
                <p>Cultivar: {{ item.subarea && item.subarea.name ? item.subarea.name : '-' }}</p> 
              </div>
              <p>Título: {{ item.title }}</p> 
              <p>Subarea: {{ item.subarea.name }}</p> 
              <p>Talhão: {{ item.subarea.field.name }}</p> 
              <button class="btn btn--flat" data-toggle="collapse" :data-target="`#${i}`" aria-expanded="false" :aria-controls="`${i}`" style="color: green; width: 100%;">Ver mais </button>
            </div>
          </div>
          <div v-else-if="item.type == 'pulverization' && (i%2) != 0" class="timeline-item">

            <div class="timeline-content-date left">
              <p style="margin-left: 370px;">Pulverização</p>
              <p style="font-size: 15px; margin-left: 370px;">
                {{ item.activityDate.dateHour | formatDate }}
              </p>
            </div>

            <div class="timeline-icon-pulverizacao">
              <img src="@/assets/img/prague.png">
            </div>

            <div class="timeline-content right">
              <div :id="i" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <p>Notas: {{ item.notes }}</p>
                <p v-for="itemMachinery in item.machineries" :key="itemMachinery.machinery.model">Equipamento: {{ itemMachinery.machinery.model }}</p>
                <p v-if="item.area || item.area === 0">Área utilizada (ha): {{ item.area  | formatNumber }}</p>
                <p>Vazão (L/ha): {{ item.flowRate.value }}</p>
                <p v-for="itemProducts in item.products" :key="itemProducts.product.name">Produto: {{ itemProducts.product.name }}</p>
                <p>Cultivar: {{ item.subarea && item.subarea.name ? item.subarea.name : '-' }}</p> 
              </div>
              <p>Título: {{ item.title }}</p> 
              <p>Subarea: {{ item.subarea.name }}</p> 
              <p>Talhão: {{ item.subarea.field.name }}</p> 
              <button class="btn btn--flat" data-toggle="collapse" :data-target="`#${i}`" aria-expanded="false" :aria-controls="`${i}`" style="color: green; width: 100%;">Ver mais </button>
            </div>
          </div>

          <div v-if="item.type == 'planting' && (i%2) != 1" class="timeline-item">

            <div class="timeline-content-date right">
              <p>Plantio</p>
              <p style="font-size: 15px;">
                {{ item.activityDate.dateHour | formatDate }}
              </p>
            </div>

            <div class="timeline-icon-plantio">
              <img src="@/assets/agriculturex.png">
            </div>

            <div class="timeline-content">
              <div :id="i" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <p v-if="item.area || item.area === 0">Área utilizada (ha): {{ item.area  | formatNumber }}</p>
                <p>População de plantas: {{ item.plantPopulation.value }}</p>
                <p>Notes: {{ item.notes }}</p>
                <p v-if="item.treatmentSeed != true">Tratamento de sementes: Não</p>
                <p v-else>Tratamento de sementes: Sim</p>
                <p>Espaçamento entre linhas (cm): {{ item.spaceline.value }}</p>
                <p>Adubação de cobertura: {{ item.plantStand.value }}</p>
              </div>
              <p>Título: {{ item.title }}</p> 
              <p>Subarea: {{ item.subarea.name }}</p> 
              <p>Talhão: {{ item.subarea.field.name }}</p> 
              <button class="btn btn--flat" data-toggle="collapse" :data-target="`#${i}`" aria-expanded="false" :aria-controls="`${i}`" style="color: green; width: 100%;">Ver mais </button>
            </div>
          </div>
          <div v-if="item.type == 'planting' && (i%2) != 0" class="timeline-item">

            <div class="timeline-content-date left">
              <p style="margin-left: 370px;">Plantio</p>
              <p style="font-size: 15px; margin-left: 370px">
                {{ item.activityDate.dateHour | formatDate }}
              </p>
            </div>

            <div class="timeline-icon-plantio">
              <img src="@/assets/agriculturex.png">
            </div>

            <div class="timeline-content right">
              <div :id="i" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <p v-if="item.area || item.area === 0">Área utilizada (ha): {{ item.area  | formatNumber }}</p>
                <p>População de plantas: {{ item.plantPopulation.value }}</p>
                <p>Notes: {{ item.notes }}</p>
                <p v-if="item.treatmentSeed != true">Tratamento de sementes: Não</p>
                <p v-else>Tratamento de sementes: Sim</p>
                <p>Espaçamento entre linhas (cm): {{ item.spaceline.value }}</p>
                <p>Adubação de cobertura: {{ item.plantStand.value }}</p>
              </div>
              <p>Título: {{ item.title }}</p> 
              <p>Subarea: {{ item.subarea.name }}</p> 
              <p>Talhão: {{ item.subarea.field.name }}</p> 
              <button class="btn btn--flat" data-toggle="collapse" :data-target="`#${i}`" aria-expanded="false" :aria-controls="`${i}`" style="color: green; width: 100%;">Ver mais </button>
            </div>
          </div>

          <div v-if="item.type == 'soilPreparation' && (i%2) != 1" class="timeline-item">

            <div class="timeline-content-date right">
              <p>Preparo de solo</p>
              <p style="font-size: 15px">
                {{ item.activityDate.dateHour | formatDate }}
              </p>
            </div>

            <div class="timeline-icon-plantio">
              <img src="@/assets/prepsol.png">
            </div>

            <div class="timeline-content">
              <div :id="i" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <p v-for="itemMachinery in item.machineries" :key="itemMachinery.machinery.model">Equipamento: {{ itemMachinery.machinery.model }}</p>
                <p v-if="item.area || item.area === 0">Área utilizada (ha): {{ item.area  | formatNumber }}</p>
                <p>Notas: {{ item.notes }}</p>
                <p v-for="itemProducts in item.products" :key="itemProducts.product.name">Produto: {{ itemProducts.product.name }}</p>
                <p>Fase da cultura: {{ culturePhase && culturePhase.abbreviation ? culturePhase.abbreviation : '-' }}</p>
              </div>
                <p>Título: {{ item.title }}</p> 
                <p>Subarea: {{ item.subarea.name }}</p> 
                <p>Talhão: {{ item.subarea.field.name }}</p> 
              <button class="btn btn--flat" data-toggle="collapse" :data-target="`#${i}`" aria-expanded="false" :aria-controls="`${i}`" style="color: green; width: 100%;">Ver mais </button>
            </div>
          </div>
          <div v-if="item.type == 'soilPreparation' && (i%2) != 0" class="timeline-item">

            <div class="timeline-content-date left">
              <p style="margin-left: 370px;">Preparo de solo</p>
              <p style="font-size: 15px; margin-left: 370px">
                {{ item.activityDate.dateHour | formatDate }}
              </p>
            </div>

            <div class="timeline-icon-plantio">
              <img src="@/assets/prepsol.png">
            </div>

            <div class="timeline-content right">
              <div :id="i" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <p v-for="itemMachinery in item.machineries" :key="itemMachinery.machinery.model">Equipamento: {{ itemMachinery.machinery.model }}</p>
                <p v-if="item.area || item.area === 0">Área utilizada (ha): {{ item.area  | formatNumber }}</p>
                <p>Notas: {{ item.notes }}</p>
                <p v-for="itemProducts in item.products" :key="itemProducts.product.name">Produto: {{ itemProducts.product.name }}</p> 
                <p>Fase da cultura: {{ culturePhase && culturePhase.abbreviation ? culturePhase.abbreviation : '-' }}</p> 
              </div>
              <p>Título: {{ item.title }}</p> 
              <p>Subarea: {{ item.subarea.name }}</p> 
              <p>Talhão: {{ item.subarea.field.name }}</p> 
              <button class="btn btn--flat" data-toggle="collapse" :data-target="`#${i}`" aria-expanded="false" :aria-controls="`${i}`" style="color: green; width: 100%;">Ver mais </button>
            </div>
          </div>

          <div v-if="item.type == 'seedTreatment' && (i%2) != 1" class="timeline-item">

            <div class="timeline-content-date right">
              <p>Tratamento de sementes</p>
              <p style="font-size: 15px;">
                {{ item.activityDate.dateHour | formatDate }}
              </p>
            </div>

            <div class="timeline-icon-plantio">
              <img src="@/assets/tratsem.png">
            </div>

            <div class="timeline-content">
              <div :id="i" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <p v-for="itemMachinery in item.machineries" :key="itemMachinery.machinery.model">Equipamento: {{ itemMachinery.machinery.model }}</p>
                <p>Semente: {{ item.seedToTreat && item.seedToTreat.stockProduct && item.seedToTreat.stockProduct.product && item.seedToTreat.stockProduct.product.name }}</p>
                <p>Notas: {{ item.notes }}</p>
                <p v-for="itemProducts in item.products" :key="itemProducts.product.name">Produto: {{ itemProducts.product.name }}</p>   
                <p>Fase da cultura: {{ culturePhase && culturePhase.abbreviation ? culturePhase.abbreviation : '-' }}</p>
              </div>
              <p>Título: {{ item.title }}</p> 
              <p>Subarea: {{ item.subarea.name }}</p> 
              <p>Talhão: {{ item.subarea.field.name }}</p> 
              <button class="btn btn--flat" data-toggle="collapse" :data-target="`#${i}`" aria-expanded="false" :aria-controls="`${i}`" style="color: green; width: 100%;">Ver mais </button>
            </div>
          </div>
          <div v-if="item.type == 'seedTreatment' && (i%2) != 0" class="timeline-item">

            <div class="timeline-content-date left">
              <p style="margin-left: 370px;">Tratamento de sementes</p>
              <p style="font-size: 15px; margin-left: 370px">
                {{ item.activityDate.dateHour | formatDate }}
              </p>
            </div>

            <div class="timeline-icon-plantio">
              <img src="@/assets/tratsem.png">
            </div>

            <div class="timeline-content right">
              <div :id="i" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <p v-for="itemMachinery in item.machineries" :key="itemMachinery.machinery.model">Equipamento: {{ itemMachinery.machinery.model }}</p>
                <p>Semente: {{ item.seedToTreat && item.seedToTreat.stockProduct && item.seedToTreat.stockProduct.product && item.seedToTreat.stockProduct.product.name }}</p>
                <p>Notas: {{ item.notes }}</p>
                <p v-for="itemProducts in item.products" :key="itemProducts.product.name">Produto: {{ itemProducts.product.name }}</p>  
                <p>Fase da cultura: {{ culturePhase && culturePhase.abbreviation ? culturePhase.abbreviation : '-' }}</p>  
              </div>
                <p>Título: {{ item.title }}</p> 
                <p>Subarea: {{ item.subarea.name }}</p> 
                <p>Talhão: {{ item.subarea.field.name }}</p> 
              <button class="btn btn--flat" data-toggle="collapse" :data-target="`#${i}`" aria-expanded="false" :aria-controls="`${i}`" style="color: green; width: 100%;">Ver mais </button>
            </div>
          </div>

          <div v-if="item.type == 'otherActivity' && (i%2) != 1" class="timeline-item">

            <div class="timeline-content-date right">
              <p>Outra atividade</p>
              <p style="font-size: 15px;">
                {{ item.activityDate.dateHour | formatDate }}
              </p>
            </div>

            <div class="timeline-icon-plantio">
              <img src="@/assets/outraativ.png">
            </div>

            <div class="timeline-content">
              <div :id="i" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <p v-for="itemMachinery in item.machineries" :key="itemMachinery.machinery.model">Equipamento: {{ itemMachinery.machinery.model }}</p>
                <p v-for="itemOperator in item.operators" :key="itemOperator.operator.name">Operador: {{ itemOperator.operator.name }}</p>
                <p v-if="item.area || item.area === 0">Área utilizada (ha): {{ item.area  | formatNumber }}</p>
                <p>Notas: {{ item.notes }}</p>
                <p>Safra: {{ item.subarea.crop.name }}</p> 
                <p v-for="itemProducts in item.products" :key="itemProducts.product.name">Produto: {{ itemProducts.product.name }}</p>   
                <p>Fase da cultura: {{ item.culturePhase && item.culturePhase.abbreviation ? item.culturePhase.abbreviation : '-' }}</p> 
              </div>
              <p>Título: {{ item.title }}</p> 
              <p>Subarea: {{ item.subarea.name }}</p> 
              <p>Talhão: {{ item.subarea.field.name }}</p> 
              <button class="btn btn--flat" data-toggle="collapse" :data-target="`#${i}`" aria-expanded="false" :aria-controls="`${i}`" style="color: green; width: 100%;">Ver mais </button>
            </div>
          </div>
          <div v-if="item.type == 'otherActivity' && (i%2) != 0" class="timeline-item">

            <div class="timeline-content-date left">
              <p style="margin-left: 370px;">Outra atividade</p>
              <p style="font-size: 15px; margin-left: 370px">
                {{ item.activityDate.dateHour | formatDate }}
              </p>
            </div>

            <div class="timeline-icon-plantio">
              <img src="@/assets/outraativ.png">
            </div>

            <div class="timeline-content right">
              <div :id="i" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <p v-for="itemMachinery in item.machineries" :key="itemMachinery.machinery.model">Equipamento: {{ itemMachinery.machinery.model }}</p>
                <p v-for="itemOperator in item.operators" :key="itemOperator.operator.name">Operador: {{ itemOperator.operator.name }}</p>
                <p v-if="item.area || item.area === 0">Área utilizada (ha): {{ item.area  | formatNumber }}</p>
                <p>Notas: {{ item.notes }}</p> 
                <p>Safra: {{ item.subarea.crop.name }}</p> 
                <p v-for="itemProducts in item.products" :key="itemProducts.product.name">Produto: {{ itemProducts.product.name }}</p>   
                <p>Fase da cultura: {{ item.culturePhase && item.culturePhase.abbreviation ? item.culturePhase.abbreviation : '-' }}</p> 
              </div>
              <p>Título: {{ item.title }}</p> 
              <p>Subarea: {{ item.subarea.name }}</p> 
              <p>Talhão: {{ item.subarea.field.name }}</p> 
              <button class="btn btn--flat" data-toggle="collapse" :data-target="`#${i}`" aria-expanded="false" :aria-controls="`${i}`" style="color: green; width: 100%;">Ver mais </button>
            </div>
          </div>

          <div v-if="item.type == 'fertilization' && (i%2) != 1" class="timeline-item">

            <div class="timeline-content-date right">
              <p>Fertilização</p>
              <p style="font-size: 15px;">
                {{ item.activityDate.dateHour | formatDate }}
              </p>
            </div>

            <div class="timeline-icon-pulverizacao">
              <img src="@/assets/fert.png">
            </div>

            <div class="timeline-content">
              <div :id="i" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <p v-for="itemMachinery in item.machineries" :key="itemMachinery.machinery.model">Equipamento: {{ itemMachinery.machinery.model }}</p>
                <p v-if="item.area || item.area === 0">Área utilizada (ha): {{ item.area  | formatNumber }}</p>
                <p>Notas: {{ item.notes }}</p>
                <p v-for="itemProducts in item.products" :key="itemProducts.product.name">Produto: {{ itemProducts.product.name }}</p>    
                <p>Fase da cultura: {{ culturePhase && culturePhase.abbreviation ? culturePhase.abbreviation : '-' }}</p> 
                <p>Cultivar: {{ item.subarea && item.subarea.name ? item.subarea.name : '-' }}</p> 
              </div>
              <p>Título: {{ item.title }}</p> 
              <p>Subarea: {{ item.subarea.name }}</p> 
              <p>Talhão: {{ item.subarea.field.name }}</p> 
              <button class="btn btn--flat" data-toggle="collapse" :data-target="`#${i}`" aria-expanded="false" :aria-controls="`${i}`" style="color: green; width: 100%;">Ver mais </button>
            </div>
          </div>
          <div v-else-if="item.type == 'fertilization' && (i%2) != 0" class="timeline-item">

            <div class="timeline-content-date left">
              <p style="margin-left: 370px;">Fertilização</p>
              <p style="font-size: 15px; margin-left: 370px">
                {{ item.activityDate.dateHour | formatDate }}
              </p>
            </div>

            <div class="timeline-icon-pulverizacao">
              <img src="@/assets/fert.png">
            </div>

            <div class="timeline-content right">
              <div :id="i" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <p v-for="itemMachinery in item.machineries" :key="itemMachinery.machinery.model">Equipamento: {{ itemMachinery.machinery.model }}</p>
                <p v-if="item.area || item.area === 0">Área utilizada (ha): {{ item.area  | formatNumber }}</p>
                <p>Notas: {{ item.notes }}</p>
                <p v-for="itemProducts in item.products" :key="itemProducts.product.name">Produto: {{ itemProducts.product.name }}</p>    
                <p>Fase da cultura: {{ culturePhase && culturePhase.abbreviation ? culturePhase.abbreviation : '-' }}</p> 
                <p>Cultivar: {{ item.subarea && item.subarea.name ? item.subarea.name : '-' }}</p> 
              </div>
              <p>Título: {{ item.title }}</p> 
              <p>Subarea: {{ item.subarea.name }}</p> 
              <p>Talhão: {{ item.subarea.field.name }}</p> 
              <button class="btn btn--flat" data-toggle="collapse" :data-target="`#${i}`" aria-expanded="false" :aria-controls="`${i}`" style="color: green; width: 100%;">Ver mais </button>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldOverviewMap from "@/views/configurations/fields/overview/components/FieldOverviewMap.vue";
import FieldOverviewNDVI from "@/views/configurations/fields/overview/components/FieldOverviewNDVI.vue";
import subAreas from "@/api/Subarea";
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'FieldOverview',
  components: {
      FieldOverviewMap,
      FieldOverviewNDVI
  },
  data() {
    return {
      myFieldsWithSubareas: [],
      setFields: [],
      showPlantings: undefined,
      showPulverization: undefined,
      show: undefined,
      allPlantings: [],
      allHarvests: [],
      allPulverizations: [],
      allSubareas: [],
      myPlantings: [],
      myHarvests: [],
      myPulverizations: [],
      activitiesLoaded: false,
      myActivities: [],
      localization: true,
      lifecycle: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters("fields", ["getFieldSelected", "getImagesNDVI", "getSubAreas", "myFields", "allFields"]),
    ...mapGetters("property", ["getPropertySelected", "getPropertyFields", "getCropSelected", "getAllActivities"]),
    ...mapGetters("activities", ["getActivities"]),
    fieldSelected() {
      return this.getFieldSelected;
    },
  },
  async mounted() {
    await this.fetchField(this.$route.params.id);
    await this.fetchNDVIS(this.$route.params.id);
    //const cropToFind = this.getCropSelected
    await this.findActivities({ cropId: this.getCropSelected._id, fieldId: this.$route.params.id })
    await this.setSubAreasInFieldSelected();
    this.setMyActivities()
  },
  methods: {
    ...mapActions("property", ["listFields", "listAllPropertyActivities"]),
    ...mapActions("fields", ["getField", "listFieldAllImagesNDVI", "listSubAreas", "setMyFields"]),
    ...mapActions("activities", ["findActivities"]),
    ...mapActions("reports", ["findFieldLifeReport"]),

    async downloadPdf(){
      this.loading = true
      
      const payload = {
        propertyId: this.getPropertySelected._id,
        fieldId: this.$route.params.id
      }
      const response = await this.findFieldLifeReport(payload)
      if (response.data && response.data.pdf) {
        this.$vToastify.success('PDF gerado com sucesso!')
        this.goToPage(response.data.pdf)
      }
      
      this.loading = false
    },

    goToPage(url){
      window.open(url, '_blank')
    },

    async setSubAreasInFieldSelected(){
      await subAreas.findByPropertyAndCrop(this.getPropertySelected._id, this.getCropSelected._id).then((res) => {
        this.allSubAreas = res
        this.getFieldSelected.subarea = []
        this.allSubAreas.filter(item => {
          if (item.field._id === this.getFieldSelected._id) {
            this.getFieldSelected.subarea.push(item)
          }
        })
      })
    },

    async fetchSubareas() {
      await subAreas.getAll().then((response) => {
          let arr = response.data.data
          this.filterSubareas(arr)
      });
    },

    filterSubareas(arr){
      let a = localStorage.getItem("fieldId")
      let b = []
      for (let i = 0; i < arr.length; i++){
        if (arr[i].field._id == a){
          b.push(arr[i])
        }
      }
      this.allSubareas = b
    },

    setMyActivities(){
      this.myActivities = this.getActivities
      
      this.activitiesLoaded = true
    },

    fetchField(id) {
      return this.getField(id);
    },

    fetchNDVIS(id) {
      return this.listFieldAllImagesNDVI(id);
    },

    showLocalization() {
        this.lifecycle = false
        this.localization = true
    },

    showLife() {
        this.localization = false
        this.lifecycle = true
    },
  },
};
</script>

<style scoped lang="sass">
.field-overview
    height: 100%
    width: 100%
    display: flex
    grid-gap: 10px
    flex-direction: column

    &__header
        display: flex
        justify-content: space-between

        .title
            font-size: 24px
            color: $theme-color-primary

    &__nav
        display: flex
        justify-content: center
        height: 36px

        .btn:focus
            background: unset

        .active
            border-bottom: $border

    &__localization
        display: flex
        grid-gap: 6px
        height: calc(100% - 36px)
        width: 100%
        text-align: center

        .map-container
            width: 40%

            .map
                height: calc(100% - 22px)

        .full-width
            width: 100%

        .ndvi-container
            width: 60%

            .ndvi
                padding: 30px 0
                height: calc(100% - 22px)

@media (max-width: 960px)
    .field-overview .field-overview__localization
        flex-direction: column

        .map-container, .ndvi-container
            width: 100%
            height: 400px

@media (max-width: 600px)
    .field-overview
        &__breadcrumb
            display: none

        &__header
            grid-gap: 6px
            flex-direction: column
            align-items: center

            .btn
                width: 100px

        &__nav
            flex-direction: column
            height: unset

            .active
                border: $border
</style>

<style scoped>
/* timeline */

@import "https://fonts.googleapis.com/css?family=Dosis:300,400,500,600,700";
#timeline .timeline-item:after,
header:after,
#timeline .timeline-item:before,
header:before {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

body,
html {
  height: 100%;
}

body {
  background: #f9f9f9;
  background-size: cover;
  margin: 0;
  padding: 0;
  line-height: 20px;
  font-size: 14px;
  color: #726f77;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
}

.project-name {
  text-align: center;
  padding: 10px 0;
}

header {
  background: #2b2e48;
  padding: 10px;
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
}
header .logo {
  float: left;
  font-size: 22px;
  font-weight: 500;
}
header .logo > span {
  font-weight: 300;
}
header .social {
  float: right;
}
header .social .btn {
  font-size: 14px;
  margin: 10px 5px;
}

#timeline {
  width: 100%;
  margin: 30px auto;
  position: relative;
  padding: 0 10px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
#timeline:before {
  content: "";
  width: 3px;
  height: 100%;
  background: #CECECE;
  left: 50%;
  top: 0;
  position: absolute;
}
#timeline:after {
  content: "";
  clear: both;
  display: table;
  width: 100%;
}
#timeline .timeline-item {
  margin-bottom: 50px;
  position: relative;
}
#timeline .timeline-item .timeline-icon-colheita {
  border: 1px solid white;
  background: #12AD00;
  width: 85px;
  height: 85px;
  position: absolute;
  top: 0;
  left: 48%;
  overflow: hidden;
  margin-left: -20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000029;
}
#timeline .timeline-item .timeline-icon-pulverizacao {
  border: 1px solid white;
  background: #E64700;
  width: 85px;
  height: 85px;
  position: absolute;
  top: 0;
  left: 40%;
  overflow: hidden;
  margin-left: 65px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000029;
}
#timeline .timeline-item .timeline-icon-plantio {
  border: 1px solid white;
  background: #009DFF;
  width: 85px;
  height: 85px;
  position: absolute;
  top: 0;
  left: 48%;
  overflow: hidden;
  margin-left: -18px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000029;
}
#timeline .timeline-item .timeline-icon-colheita img {
  position: relative;
  margin-left: 22px;
  margin-top: 15px;
}
#timeline .timeline-item .timeline-icon-pulverizacao img {
  position: relative;
  top: 22px;
  left: 22px;
}
#timeline .timeline-item .timeline-icon-plantio img {
  position: relative;
  margin-left: 20px;
  margin-top: 18px;
}
img {
  max-width: 50%;
}
#timeline .timeline-item .timeline-content {
  width: 45%;
  background: #FAFAFA;
  border: 1px solid #9B9B9B;
  padding: 20px;
  -webkit-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#timeline .timeline-item .timeline-content-date {
  width: 45%;
}
#timeline .timeline-item .timeline-content-pulverization {
  width: 45%;
  float: left;
}
#timeline .timeline-item .timeline-content-planting {
  width: 45%;
  float: right;
}
#timeline .timeline-item .timeline-content h2 {
  padding: 15px;
  color: #fff;
  margin: -20px -20px 0 -20px;
  font-weight: 300;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}
#timeline .timeline-item .timeline-content:before {
  content: "";
  position: absolute;
  left: 45%;
  top: 30px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid black;
}
#timeline .timeline-item .timeline-content.right {
  float: right;
}
#timeline .timeline-item .timeline-content-date.right {
  float: right;
}
#timeline .timeline-item .timeline-content-date.left {
  float: left;
}
#timeline .timeline-item .timeline-content.right:before {
  content: "";
  right: 45%;
  left: inherit;
  border-left: 0;
  border-right: 7px solid black;
}

.report-toast {
  margin-right: 5px;
}

@media screen and (max-width: 768px) {
  #timeline {
    margin: 30px;
    padding: 0px;
    width: 90%;
  }
  #timeline:before {
    left: 0;
  }
  #timeline .timeline-item .timeline-content {
    width: 90%;
    float: right;
  }
  #timeline .timeline-item .timeline-content:before,
  #timeline .timeline-item .timeline-content.right:before {
    left: 10%;
    margin-left: -6px;
    border-left: 0;
    border-right: 7px solid #ee4d4d;
  }
  #timeline .timeline-item .timeline-icon {
    left: 0;
  }
}
</style>
